@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blur-content {
  filter: blur(10px);
  pointer-events: none;
}

/* Prevent viewport changes on mobile devices */
html, body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}



@viewport {
  width: device-width;
  zoom: 1.0;
  min-zoom: 1.0;
  max-zoom: 1.0;
  user-zoom: fixed;
}

* {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  touch-action: manipulation;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}

@media screen and (max-width: 768px) {
  input[type="text"],
  input[type="number"],
  select,
  textarea {
    font-size: 16px !important;
  }
}

/* Fix for mobile tap delay */
* {
  touch-action: manipulation;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}
.blur-content {
  filter: blur(10px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  z-index: 1000;
}



