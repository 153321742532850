/* .exam-container {
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .question-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  
  .chapter-name {
    color: #007bff;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .question-content {
    color: #444;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  .hints {
    color: #28a745;
    font-size: 14px;
    margin-top: 10px;
    padding: 10px;
    border-left: 4px solid #28a745;
    background-color: #e9f8ec;
  }
  
  .weightage {
    color: #555;
    font-size: 14px;
    text-align: right;
    margin-top: 10px;
  }
  
  .loading,
  .error {
    text-align: center;
    color: #888;
    font-size: 18px;
  }
  
  .error {
    color: #dc3545;
  }
  
  .no-questions {
    text-align: center;
    color: #6c757d;
    font-size: 16px;
  } */



  .exam-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    font-size: 2.5rem;
  }
  
  .question-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .question-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  /* .question-content {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .question-number {
    font-weight: bold;
    color: #007bff;
    margin-right: 5px;
  }
   */

   .question-content {
    display: flex; /* Aligns items in a row */
    align-items: flex-start; /* Ensures alignment starts at the top */
  }
  
  .question-number {
    margin-right: 8px; /* Spacing between the number and text */
    line-height: 1.5; /* Matches the question text line height */
    font-weight: bold;
    color: #007bff;
  }
  
  .question-text {
    flex: 1; /* Ensures the text takes up remaining space */
    line-height: 1.5; /* Set line height for proper alignment */
  }
  
  
  .hints {
    background-color: #e6f3ff;
    border-left: 4px solid #007bff;
    padding: 10px 15px;
    margin-top: 15px;
    font-style: italic;
    color: #555;
  }
  
  .weightage {
    margin-top: 15px;
    font-weight: bold;
    color: #28a745;
  }
  
  .loading, .error, .no-questions {
    text-align: center;
    padding: 20px;
    font-size: 1.2rem;
    color: #666;
  }
  
  .error {
    color: #dc3545;
  }
  
  @media (max-width: 600px) {
    .exam-container {
      padding: 10px;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .question-card {
      padding: 15px;
    }
  }
  
  
  