.chapter-card-container {
    padding: 2rem;
    background-color: #f0f4f8;
    min-height: 100vh;
  }
  
  .main-title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .card-grid.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .chapter-card {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .chapter-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .card-image-container {
    position: relative;
    overflow: hidden;
  }
  
  .card-image {
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .chapter-card:hover .card-image {
    transform: scale(1.05);
  }
  
  .card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
    padding: 1rem;
    transition: opacity 0.3s ease;
  }
  
  .card-title {
    color: white;
    font-size: 1rem;
    margin: 0;
    text-align: center;
  }
  
  .take-exam-btn {
    width: 100%;
    background-color: #3498db;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .take-exam-btn:hover {
    background-color: #2980b9;
  }
  
  .card-animation {
    animation: fadeInUp 0.5s ease-out forwards;
    opacity: 0;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .chapter-card-container {
      padding: 1rem;
    }
  
    .main-title {
      font-size: 2rem;
    }
  
    .card-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;
    }
  }
  
  