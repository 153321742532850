/* :root {
  --option-base-width: auto;
  margin-top: 7%;
}

body {
  zoom: 100%;
 
}

.option {
  cursor: pointer;
  padding: 10px 15px;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;
  background-color: #fff;
  color: #333;
 
  display: inline-flex;
  align-items: center;
  width: fit-content;
  max-width: 70%;
  white-space: normal; 
}

.option.selected {
  background-color: #175cf1;
  color: #fff;
}

.option.correct {
  color: #0bfc0b;
}

.option.wrong {
  background-color: #ed0303;
}

.option-text {
  margin-left: 10px;
}





.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stats p {
  background: #e8f4fc;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
  color: #0073e6;
}

.quiz-container {
  text-align: center;
  margin-top: 7%;
  user-select: none;
}

.result-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}


button.submit-button,
button.show-result-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

button.submit-button {
  background-color: #007bff; 
  color: #fff;
}

button.show-result-button {
  background-color: #28a745; 
  color: #fff; 
}

.attendance-count {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ccc;
  padding: 5px;
  border-radius: 4px;
  margin-top: 7%;
  position: fixed;
}


.time-display {
  position: fixed;
  margin-left: 90%;
  top: 50px;
  right: 10px;
  left:90%;
  background-color: #ccc;
  padding: 5px;
  border-radius: 4px;
  margin-top: 7%;
  position: fixed;
  white-space: nowrap; 
  display: inline-block; 
  text-align: right;
  text-overflow: clip;
  display: inline-block;
  max-width: fit-content;
}



@media screen and (max-width: 418px) {
  .option {
    padding: 6px 10px;
    font-size: 12px;
    display: inline-flex;
  }

  .quiz-container {
    margin-top: 15%;
  }


  .time-display,
  .attendance-count {
    position: fixed;
    background-color: #ccc;
    padding: 5px;
    border-radius: 4px;
    top: 100px;
    right: 10px;
    z-index: 999;
    margin-top: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 419px) and (max-width: 768px) {

  .option {
    padding: 8px 12px;
    font-size: 14px;
    max-width: 100%;
    margin-right: 10%;
    display: inline-flex;
  }

  .quiz-container {
    margin: 10%;
    margin-top: 10%;
    text-align: center;
  }
  .time-display,
  .attendance-count {
    position: fixed;
    margin-top: 100;
    top: 200;
  }
}

@media screen and (min-width: 769px) and (max-width: 912px) {

  .option {
    padding: 10px 15px;
    font-size: 16px;
    margin-right: 10%;
    max-width: 100%;
    display: inline-flex;
  }

  .quiz-container {
    margin-top: 7%;
    margin: 10%;
    text-align: center;
  }

  .time-display {
    position: fixed;
    margin-top: 55;
    top: 10;
    left :15;
  }

  .attendance-count {
    position: fixed;
    margin-top: 55;
    top: 10;
  }
}

@media screen and (min-width: 913px) {

  .option {
    padding: 10px 15px;
    font-size: 18px;
    margin-right: 10%;
    max-width: 70%;
    display: inline-flex;
  }

  .quiz-container {
    margin-top: 7%;
    margin:10%;
    text-align: center;
  }

  .time-display{
    position: fixed;
    margin-top: 55;
    top: 10;
    left:10px;
  }

  .attendance-count {
    position: fixed;
    margin-top: 55;
    top: 10;
  }


 }

@media (max-width: 767px) {
 
  .time-display{
    left: auto; 
    margin-left: 0; 
    right: 10px; 
    top: 10px; 
  }

  .attendance-count {
    position: fixed;
    margin-top:65px;
    top: 10;
  }
}



@media print {
  html, body {
     display: none;  
  }
} */



:root {
  --primary-color: #007bff;
  --secondary-color: #28a745;
  --background-color: #27ff6f3d;
  --text-color: #2d3748;
  --border-color: #e2e8f0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: system-ui, -apple-system, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
  user-select: none; 
  -webkit-user-select: none;
  -ms-user-select: none;
}

.quiz-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 6rem 1rem 2rem 1rem; /* Added top padding to prevent navbar overlap */
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-height: 100vh;
}

h6 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 2rem;
  font-weight: 600;
}

.attendance-count {
  position: fixed;
  top: 4.5rem; /* Position below navbar */
  right: 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 500;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.timer {
  position: fixed;
  top: 8.5rem; /* Position below navbar */
  right: 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 500;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.option {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 1rem 1.25rem;
  margin-bottom: 0.75rem;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-align: left;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}

.option:hover {
  transform: translateY(-1px);
  border-color: var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.1);
}

.option.selected {
  background-color: var(--primary-color);
  color: #ffffff;
  border-color: var(--primary-color);
}

.option.correct {
  background-color: var(--secondary-color);
  color: #ffffff;
  border-color: var(--secondary-color);
}

.option.wrong {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #dc3545;
}

.option-text {
  font-size: 1rem;
  line-height: 1.5;
}

.submit-button,
.show-result-button {
  display: inline-block;
  padding: 0.875rem 2rem;
  margin: 1rem 0;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.submit-button {
  background-color: var(--primary-color);
  color: #ffffff;
}

.show-result-button {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.submit-button:hover,
.show-result-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.result-details {
  margin-top: 1.5rem;
  padding: 1.25rem;
  background-color: #f8fafc;
  border-radius: 8px;
  border-left: 4px solid var(--primary-color);
}

/* Mobile Viewport Fix */
@viewport {
  width: device-width;
  zoom: 1.0;
  max-zoom: 1.0;
  user-zoom: fixed;
}
/* Fix for iOS form elements */
input, select, textarea {
  font-size: 16px !important;
  max-width: 100%;
}

/* Prevent pull-to-refresh on mobile */
body {
  overscroll-behavior-y: contain;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .quiz-container {
    padding: 5rem 1rem 1rem 1rem;
    margin: 0;
    border-radius: 0;
  }

  h6 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .option {
    padding: 0.875rem 1rem;
  }

  .attendance-count {
    top: 4rem;
    right: 0.5rem;
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
  }

  .submit-button,
  .show-result-button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }

  .result-details {
    padding: 1rem;
    margin-top: 1rem;
    font-size: 0.875rem;
  }
}

/* Tablet Responsiveness */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .quiz-container {
    padding: 5.5rem 2rem 2rem 2rem;
    margin: 0 1rem;
  }
}

/* Prevent text selection on options */
.option {
  user-select: none;
  -webkit-user-select: none;
}

/* Smooth transition for all interactive elements */
* {
  transition: all 0.2s ease-in-out;
}

/* Print styles */
@media print {
  .quiz-container {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .submit-button,
  .show-result-button,
  .attendance-count {
    display: none;
  }
}


