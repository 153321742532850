.profile-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #666;
}

.expired {
  color: #dc2626;
  font-weight: 600;
}

@media (max-width: 768px) {
  .profile-content {
    padding: 1rem;
  }
  
  .info-grid {
    grid-template-columns: 1fr;
  }
  
  .qr-container img {
    width: 200px;
    height: 200px;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-container p {
  display: inline-block;
  animation: marquee 20s linear infinite;
}
.profile-header {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-header h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.marquee-container {
  background: linear-gradient(90deg, #ff7e7e, #ffbcbc);
  color: #8b0000;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.marquee-container p {
  display: inline-block;
  animation: scroll 20s linear infinite;
  margin: 0;
  padding-left: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-section {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.profile-section h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
}

.info-grid {
  display: grid;
  gap: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-item strong {
  font-weight: 600;
  color: #555;
  margin-bottom: 0.25rem;
}

.info-item span {
  color: #333;
}

.button-container {
  margin-top: 1rem;
  text-align: right;
}

.expired {
  color: #dc3545;
  font-weight: 600;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #007bff;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 1rem;
    margin: 1rem;
  }

  .profile-header h1 {
    font-size: 2rem;
  }

  .profile-section {
    padding: 1rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .button-container {
    text-align: center;
  }
}

@media (min-width: 769px) {
  .info-grid {
    grid-template-columns: 1fr 1fr;
  }
}

