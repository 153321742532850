/* Add these styles to your CSS file */

.card-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.card-animation.visible {
  opacity: 1;
  transform: translateY(0);
}



.marquee-container {
  width: 100%;
  overflow: hidden;
  background-color: #f0f0f0; /* Light background */
  padding: 10px;
  border: 2px solid #4CAF50; /* Stylish border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow */
  display: flex;
  align-items: center;
  justify-content: center;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: bold;
  color: #4CAF50; /* Attractive color */
  animation: marquee 10s linear infinite;
}

/* Define the marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

  